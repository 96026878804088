@use '@/styles/config/_mixins.scss' as *;

.container {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1035px;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 50px;
  color: #293737;

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }

  .heading {
    margin: 0;
    margin-bottom: 30px;
    font-size: 60px;
    line-height: 1.2;
  }

  .text {
    font-size: 32px;
    line-height: 1.375;
    text-align: center;

    a {
      color: #0f1eff;
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 44px;
    }
  }
}