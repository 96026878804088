@use '@/styles/config/_mixins.scss' as *;

.section {
  margin-top: 50px;
  margin-bottom: 50px;

  @include extra-small-break {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}